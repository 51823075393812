<template>
<div v-if="impact">
  <div
    v-for="data in getImpactSearches"
    :key="data.id"
    class="search-hostory-card"
  >
    <div class="search-hostory-card__name">
      <h3>{{ data.filters.query }} </h3>
      <i class="icon icon-add_plus" @click="showDeletePopup(data.id)"></i>
    </div>
    <p>Searched on</p>
    <div class="search-hostory-card__date">
      <p>{{ data.created_at }}</p>
      <button class="apply-filter" @click="applySavedImpactFilter(data.filters)">
        Apply filter
      </button>
    </div>
  </div>
  <div v-if="getImpactSearches.length === 0" class="search-not-found-message">
    No search history found
  </div>
  </div>
<div v-else>
  <div
    v-for="data in getRecentSearches"
    :key="data.id"
    class="search-hostory-card"
  >
    <div class="search-hostory-card__name">
      <h3>{{ data.filters.query }} </h3>
      <i class="icon icon-add_plus" @click="showDeletePopup(data.id)"></i>
    </div>
    <p>Searched on</p>
    <div class="search-hostory-card__date">
      <p>{{ data.created_at }}</p>
      <button class="apply-filter" @click="applySavedFilter(data.filters)">
        Apply filter
      </button>
    </div>
  </div>
  <div v-if="getRecentSearches.length === 0" class="search-not-found-message">
    No search history found
  </div>
  </div>
</template>
  <script>
export default {
  emits: ["show-delete-popup", "show-saved-search-popup"],
  computed: {
    getRecentSearches() {
      return this.$store.getters["discover/getRecentSearches"];
    },
    getImpactSearches() {
      return this.$store.getters["impact/getRecentImpactSearches"];
    },
  },
  props: { impact: Boolean },
  methods: {
    showDeletePopup(value) {
      this.$emit("show-delete-popup", value);
    },
    async applySavedImpactFilter(value) {
      this.emitter.emit("searching-impact-articles", true);
      const impactRequestObj = {
          query: value.query,
          offset: 0,
          fromDate: value.from_date !=  "''" ? value.from_date : null ,
          toDate: value.to_date !=  "''" ? value.to_date : null ,
          limit: 9,
          author: "",
      };
        await this.$store.dispatch("impact/fetchImpactArticle", impactRequestObj );
      this.emitter.emit("searching-impact-articles", false);
    },
    async applySavedFilter(value) {
      const articleRequestObj = {
        query: value.query,
        fromDate: value.from_date,
        toDate: value.to_date,
        includeSource: value.include_source,
        excludeSource: value.exclude_source,
        limit: 10,
        offset: 0,
        isPDB: 0,
      };
      //   let insightsRequestObj = {
      //     query: value.search_query,
      //     fromDate: value.from_date,
      //     toDate: value.to_date,
      //     includeSource: value.include_source.join(","),
      //     excludeSource: value.exclude_source.join(","),
      //     limit: 10,
      //     offset: 0,
      //   };
      await this.$store.dispatch("discover/searchArticles", articleRequestObj);
      //   await this.$store.dispatch("discover/searchInsights", insightsRequestObj);
      this.emitter.emit("search-article");
      this.emitter.emit('close-drawer')
    },
  },
};
</script>
  
<style lang="scss">
@include reset-css;
.search-hostory-card {
  background-color: #f5f5f5cb;
  padding: 1.8rem 2rem !important;
  margin-bottom: 1.5rem !important;

  h3 {
    font-size: 1.8rem;
    font-family: $font-secondary-bold;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    margin-right: 1rem;
  }
  p {
    font-size: 1.4rem;
    font-family: $font-secondary-bold;
    margin-bottom: 0.2rem;
  }
  button {
    background-color: transparent;
    height: fit-content;
    line-height: normal;
    border: none;
    outline: none;
    font-size: 1.3rem;
    font-family: $font-secondary;
    cursor: pointer;
  }
  &__name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem !important;
    .icon {
      font-size: 1.3rem;
      opacity: 0.5;
      transform: rotate(45deg);
      cursor: pointer;
      margin-left: auto;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
  &__date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 1.3rem;
      font-family: $font-secondary;
    }
  }
  .apply-filter {
    color: $color-primary;
  }
  .delete-filter {
    color: #be0000;
  }
}
.search-not-found-message {
  font-size: 1.4rem;
  font-family: $font-secondary;
  text-align: center;
  opacity: 0.8;
}
</style>
  