<template>
  <div class="research-article">
    <div class="research-article__card">
      <div class="card-header">
        <div class="article-img">
          <img src="@/assets/images/daily-mail@2x.webp" alt="" />
        </div>
        <p>
          Case study Houses: The creation of the house and other things that
        </p>
        <i class="icon icon-open_new-tab"></i>
      </div>
      <div class="card-body">
        <p>
          The passage is attributed to an unknown typesetter in the 15th century
          who is thought to have.
        </p>
      </div>
      <div class="card-footer">
        <p>
          ASIA · Fri 7:24 5:08 PM <br />
          Robert Forst, Business CNN
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include reset-css;
.research-article {
  padding-bottom: 1rem;
  background-color: $color-white;
  &__card {
    width: 100%;
    background-color: $color-white;
    padding: 1.5rem;
    position: relative;
    border: 0.2rem solid rgba(128, 128, 128, 0.377);

    .card-header {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      .icon {
        font-size: 2rem;
        opacity: 0.4;
        margin-left: 0.5rem;
      }
      p {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
        line-height: 2rem;
        margin-top: 10px;
      }
      .article-img {
        width: 15rem;
        height: 8rem;
        margin-right: 1.2rem;
        // margin-left: -2rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .card-body {
      margin-top: 0.6rem;
      margin-bottom: 1rem;
      p {
        font-size: 1.3rem;
        font-family: $font-primary;
        margin-bottom: 0;
        opacity: 0.8;
      }
    }
    .card-footer {
      width: 34rem;
      p {
        font-size: 1.4rem;
        font-family: $font-primary;
        margin-bottom: 0;
        opacity: 0.5;
      }
    }
  }
}
</style>