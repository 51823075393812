import { BOUND_BOX } from "../constants/boundingBox.js";

function generateLatLong(country){
    if (country === 'USA') {
        var lat = Math.random() * (BOUND_BOX.USA_BOUNDS.LAT[1] - BOUND_BOX.USA_BOUNDS.LAT[0] + 1)
        + BOUND_BOX.USA_BOUNDS.LAT[0];
        var lng = Math.random() * (BOUND_BOX.USA_BOUNDS.LONG[1] - BOUND_BOX.USA_BOUNDS.LONG[0] + 1)
        + BOUND_BOX.USA_BOUNDS.LONG[0];
    }
    return [lng,lat]
}

export default generateLatLong;