<template>
  <div class="intelligence-bg">
    <div class="intelligence">
      <div class="image-bg" ref="bg-img"></div>
      <the-header whiteColor intelligence>
        <template v-slot:right>
          <div class="header__right-navigation">
            <div class="weather-icon">
              <i class="icon navigation-icon icon-brightness weather-icon__icon"></i>
              <p class="weather-icon__para">91.4°F</p>
            </div>
            <i class="icon navigation-icon icon-bookmark notification__icon"></i>
            <i class="icon navigation-icon icon-notification_bell notification__icon active"
               @click="showNotificationDrawer = true"
            ></i>
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <a class="ant-dropdown-link">
                <img 
                :src="displayPicture"  
                alt="user"
                class="navigation-icon icon-account"
                />
              </a>
              <template #overlay>
                <accounts-dropdown></accounts-dropdown>
              </template>
            </a-dropdown>
          </div>
        </template>
      </the-header>
      <headline-ticker></headline-ticker>
      <search-input></search-input>
      <a-drawer
          title="Drawer"
          placement="left"
          :mask="false"
          :closable="false"
          :visible="visible"
          class="sidebar-drawer"
          width="38rem"
          :afterVisibleChange="visibleChange"
      >
        <p>Add article cards here</p>
        <a-button
            :class="{ open: visible }"
            class="toggle-btn"
            @click="showDrawer"
            ref="getdrawer"
        >
          <i class="icon icon-discover"></i>
          <i class="icon icon-forward"></i>
        </a-button>
      </a-drawer>
    </div>
    <intelligence-news></intelligence-news>
  </div>
</template>

<script>
import TheHeader from "../components/layout/ProfilePageHeader.vue";
import HeadlineTicker from "../components/PdbComponents/HeadlineTicker.vue";
import SearchInput from "../components/BaseComponents/SearchBar/SeacrhInput.vue";
import AccountsDropdown from "../components/BaseComponents/AccountsDropdown.vue";
import IntelligenceNews from "../components/IntelligenceNews/IntelligenceNews.vue";

export default {
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    displayPicture(){
      return this.$store.getters["profile/userInformation"].profile.profile_picture;
    },
  },
  components: {
    TheHeader,
    HeadlineTicker,
    SearchInput,
    AccountsDropdown,
    IntelligenceNews,
  },
  methods: {
    showDrawer() {
      this.visible = !this.visible;
    },
  },
};
</script>

<style lang="scss">
.intelligence-bg {
  background: black;

  .intelligence {
    .header__right-navigation {
      display: flex;
      align-items: center;
      z-index: 2;
      width: 100%;

      .navigation-icon {
        color: $color-white;
        margin-left: 3.1rem;
        font-size: 2rem;
        cursor: pointer;
        font-size: 3rem;
        @include respond(phone-large) {
          margin-left: 2rem;
        }
      }
      .icon-account {
        color: $color-black !important;
        border-radius: 100%;
        border: 1px solid $color-black;
        object-fit: cover;
        font-size: 3rem;
        background-color: rgb(182, 182, 182);
      }
      

      .weather-icon {
        display: flex;
        align-items: center;
        position: relative;
        color: #000;
        margin-right: 2.4rem;
        border-right: 0.22rem solid #ffffff;
        border-left: 0.22rem solid #ffffff;
        padding: 0 1rem;

        @include respond(laptop-small) {
          display: none;
        }

        &__icon {
          color: $color-white;
          font-size: 2.5rem;
          font-family: $font-secondary-bold;
          position: relative;
          margin-right: 0.7rem;
          margin-left: 0;
        }

        &__para {
          color: $color-white;
          font-size: 1.2rem;
          font-family: $font-primary-bold;
          font-weight: 400;
          margin: 0;
        }
      }

      .btn-white {
        background-color: $color-white;
        color: #191919;
        height: 3.6rem;
        border: none;
        outline: none;
        padding: 1rem 0;
        font-family: $font-secondary;
        font-size: 1.6rem;
        line-height: 1;
        margin-left: 3.1rem;
        text-align: center;
        width: 14rem;

        @include respond(phone-large) {
          display: none;
        }
      }
    }
  }
}

.sidebar-drawer {
  position: relative;

  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: visible;

      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          .toggle-btn {
            height: 5.5rem;
            background-color: #fff;
            position: absolute;
            right: 2px;
            top: 32rem;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            border: none;
            box-shadow: 1rem 1rem 5rem #000;
            transform: translateX(100%);
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .icon-discover {
            font-size: 3rem;
            color: $color-black;
            margin-right: 1rem;
            opacity: 0.8;
          }

          .icon-forward {
            display: inline-block;
            color: $color-black;
            font-size: 1.8rem;
          }

          .open {
            box-shadow: 1rem 1rem 4rem #000;

            .icon-forward {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>