<template>
  <div>
    <a-col :xs="24" :sm="24" :md="24" :lg="24" class="maps__black">
      <l-map style="height: 600px" :zoom="zoom" :center="center">
        <l-tile-layer :url="url"></l-tile-layer>
        <!-- <l-circle
          v-for="data in getData"
          :key="data"
          :lat-lng="getLatLong('USA')"
          color="green"
          :radius="getRndNumb(25000, 95000)"
          :fill="fill"
          fillColor="white"
          :fillOpacity="0.7"
          :visible="true"
        ></l-circle> -->
        <l-marker v-for="data in getData"
          :key="data" :lat-lng="getLatLong('USA')" :draggable=false>
           <l-icon>
          <div class="someExtraClass" :style="{padding: getRndNumb(4, 8) +'px'}">
          </div>
        </l-icon>
          </l-marker>
        <l-control-zoom scrollWheelZoom="false"></l-control-zoom>
      </l-map>
      
      <div class="timeline-slider">
        <div id="components-slider-demo-mark">
          <a-slider range :marks="timeLineFilter" v-model:value="timeLineVal" />
        </div>
        <a-radio-group v-model:value="options">
          <a-radio @change="onChange($event)" :value="2">Months</a-radio>
          <a-radio @change="onChange($event)" :value="1">Years</a-radio>
        </a-radio-group>
      </div>
    </a-col>
  </div>
</template>
<script>
import generateLatLong from "../../utils/bounds_lat_long";
import { LMap, LTileLayer , LMarker,LIcon } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      url: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
      zoom: 4,
      center: [39.933814233768224, -103.6323990371635],
      fill: true,
      getData: [],
      monthVal: [36, 54],
      yearVal: [60, 80],
      timeLineVal: null,
      timeLineFilter: null,
      years: {
        0: "2017",
        20: "2018",
        40: "2019",
        60: "2020",
        80: "2021",
        100: "2022",
      },
      months: {
        0: "Jan",
        9: "Feb",
        18: "Mar",
        27: "Apr",
        36: "May",
        45: "Jun",
        54: "Jul",
        63: "Aug",
        72: "Sep",
        81: "Oct",
        90: "Nov",
        100: "Dec",
      },
      options: 2,
    };
    
  },
  created() {
    this.getNewsSources();
    this.changeVal();
    this.timeLineFilter = this.months;
    this.timeLineVal = this.monthVal;
  },
  methods: {
    getRndNumb(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
      
    },
    getLatLong(country) {
      return generateLatLong(country);
    },
    changeVal() {
      this.getData = [];
      for (let i = 0; i < 100; i++) {
        this.getData.push(i);
      }
    },
    onChange(event) {
      if(event.target.value === 1){
         return (this.timeLineFilter = this.years , this.timeLineVal = this.yearVal)
      } else{
        return (this.timeLineFilter = this.months , this.timeLineVal = this.yearVal)
      }
    },
    async getNewsSources() {
      try {
        const response = await this.$store.dispatch(
          "article/getNewsSource",
        );
        if (response.status === 200) {
          console.log(response.data.results.map(artSource => {
            return{
                source: artSource.article_url.source
            }
          }));
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
};
</script>
<style lang="scss">
.timeline-slider {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  position: relative;
  #components-slider-demo-mark {
    max-width: 45rem;
    width: 100%;
    margin: auto;
    .ant-slider {
      .ant-slider-track {
        background-color: $color-primary;
      }
      .ant-slider-handle {
        border: solid 2px $color-primary;
      }
      .ant-slider-handle.ant-tooltip-open {
        border-color: $color-primary;
      }
      .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: $color-primary;
      }
      .ant-slider-dot-active {
        border-color: $color-primary;
      }
      .ant-slider:hover .ant-slider-track {
        background-color: $color-primary;
      }
      .ant-slider-dot {
        width: 11px;
        height: 11px;
        top: -3px;
      }
      .ant-slider-handle {
        top: 3px;
        width: 16px;
        height: 16px;
      }
      .ant-slider-handle:focus {
        border-color: $color-primary;
        box-shadow: 0 0 0 5px rgba(8, 110, 205, 0.2);
      }
    }
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    right: 0;
    .ant-radio-wrapper {
      margin-right: 0;
      span {
        font-size: 1.6rem;
        font-family: $font-primary-bold;
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: $color-primary;
      }
      .ant-radio {
        .ant-radio-inner::after {
          background-color: $color-primary;
        }
        .ant-radio-wrapper:hover .ant-radio,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio-input:focus + .ant-radio-inner {
          border-color: $color-primary;
        }
      }
    }
    .ant-radio-wrapper:first-child {
      margin-bottom: 0.5rem;
    }
  }
}
.someExtraClass{
      position: relative;
      width: 2px;
      height: 2px;
      background-color: #ffffff;
      border: 2px solid $color-primary;
      border-radius: 50%;
      animation: zoom-in-zoom-out 1s ease-out infinite;
      box-shadow: 0px 0px 10px 0px #ffffff;
      animation-duration: 2s;
      text-align: center;
    }
    .someExtraClass::after{
      content:"";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 10px 00px $color-primary;
      top: 0;
      left: 0;
    }
    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }
</style>