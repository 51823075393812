<template>
  <div v-for="data in savedFilters" :key="data.id" class="search-saved-card">
    <div class="search-saved-card__name">
      <h3>{{ data.filter_name }}</h3>
      <i class="icon icon-add_plus" @click="showDeletePopup(data.id)"></i>
    </div>
    <p>Search saved on</p>
    <div class="search-saved-card__date">
      <p>{{ data.created_at }}</p>
      <button class="apply-filter" @click="applySavedFilter(data.filters)">
        Apply filter
      </button>
    </div>
  </div>
  <div v-if="savedFilters.length === 0" class="search-not-found-message">
    No save search found
  </div>
</template>
<script>
export default {
  emits: ["show-delete-popup", "show-saved-search-popup"],
  computed: {
    savedFilters() {
      return this.$store.getters["discover/getSaveSearchFilters"];
    },
  },
  methods: {
    showDeletePopup(value) {
      this.$emit("show-delete-popup", value);
    },
    async applySavedFilter(value) {
      const articleRequestObj = {
        query: value.news_sources.search_query,
        fromDate: value.news_sources.from_date,
        toDate: value.news_sources.to_date,
        includeSource: value.news_sources.include_source,
        excludeSource: value.news_sources.exclude_source,
        limit: 10,
        offset: 0,
        isPDB: 0,
      };
      let researchArticleRequestObj = {
        query: value.research_publications.search_query,
        fromDate: value.research_publications.from_date,
        toDate: value.research_publications.to_date,
        includeSource: value.research_publications.include_source,
        excludeSource: value.research_publications.exclude_source,
        limit: 10,
        offset: 0,
        isPDB: 0,
      };
      await this.$store.dispatch("discover/searchArticles", articleRequestObj);
      await this.$store.dispatch("discover/searchInsights", researchArticleRequestObj);
      this.$store.commit("discover/SET_INCLUDED_SOURCES", articleRequestObj.includeSource);
      this.$store.commit("discover/SET_EXCLUDED_SOURCES", articleRequestObj.excludeSource);
      this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", researchArticleRequestObj.includeSource);
      this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", researchArticleRequestObj.excludeSource);
      this.emitter.emit("search-article");
      this.emitter.emit('close-drawer', false)
    },
  },
};
</script>

<style lang="scss">
@include reset-css;
.search-saved-card {
  background-color: #f5f5f5cb;
  padding: 1.8rem 2rem !important; //need to debug for important keyword
  margin-bottom: 1.5rem !important; //need to debug for important keyword

  h3 {
    font-size: 1.8rem;
    font-family: $font-secondary-bold;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    margin-right: 1rem;
  }
  p {
    font-size: 1.4rem;
    font-family: $font-secondary-bold;
    margin-bottom: 1rem;
  }
  button {
    background-color: transparent;
    height: fit-content;
    line-height: normal;
    border: none;
    outline: none;
    font-size: 1.3rem;
    font-family: $font-secondary;
    cursor: pointer;
  }
  &__name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem !important;
    .icon {
      font-size: 1.3rem;
      opacity: 0.5;
      transform: rotate(45deg);
      cursor: pointer;
      margin-left: auto;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
  &__date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 1.3rem;
      font-family: $font-secondary;
    }
  }
  .apply-filter {
    color: $color-primary;
  }
  .delete-filter {
    color: red;
  }
}
.search-not-found-message {
  font-size: 1.4rem;
  font-family: $font-secondary;
  text-align: center;
  margin-bottom: 2rem;
}
</style>
