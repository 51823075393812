<template>
  <div class="headline-ticker">
    <marquee-text :paused="paused" class="headline-ticker__marquee" :duration="380" :repeat="10">
      <ul @mouseenter="paused = !paused" @mouseleave="paused = false">
        <li v-for="article in articles" :key="article.id">
          <a @click="openArticle(article.id, article)">{{ article.title }}</a>
        </li>
      </ul>
    </marquee-text>
  </div>
  <div v-if="articleReadView">
    <ratings-modal
      :title="title"
      :authorName="authorName"
      :text="text"
      :image="image"
      :date="date"
      :show-rating-modal="showArticleModal"
      :isIframeAllowed="isIframeAllowed"
      :url="url"
      :id="id"
      :sources="source"
      ref="ratings-modal"
      @close-modal="showArticleModal = false"
    ></ratings-modal>
  </div>
  <subscription-modal
    :visible="visibleSubscriptionModal"
    :visibleSignUp="visibleSubscriptionModal"
    :footer="null"
    @cancel="visibleSubscriptionModal = false"
  ></subscription-modal>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";
import SubscriptionModal from "../../components/Auth/AccessModal.vue"

export default {
  components: {
    MarqueeText,
    SubscriptionModal
  },
  data() {
    return {
      articles: [],
      paused: false,
      showArticleModal: false,
      isIframeAllowed: false,
      title: "",
      authorName: "",
      text: "",
      image: "",
      date: "",
      url: "",
      source: "",
      id: "",
      articleReadView: false,
      visibleSubscriptionModal: false,
    };
  },
  mounted() {
    this.getArticles();
  },
  computed: {
    isAuthenticatedUser(){
      return this.$store.getters['auth/isAuthenticated'];
    }
  },
  methods: {
    async getArticles() {
      const response = await this.$store.dispatch("auth/getSignupArticles");
      if (response.status === 200) {
        this.articles = response.data;
      }
    },
    async getArticleData(articleId) {
      const response = await this.$store.dispatch(
        "article/getNotificationsArticleData",
        articleId
      );
      if (response.status == 200) {
        this.title = response.data.title;
        this.authorName = response.authors,
        this.text = response.data.description,
        this.image = response.data.image,
        this.date = response.data.updated_date,
        this.url = response.data.article_url.url,
        this.source = response.data.article_url.source,
        this.id = response.data.id;
        this.articleReadView = true;
        this.isIframeAllowed = this.$store.getters[
        "discover/getBlockedSourcesList"
      ].includes(this.source);
      }
    },
    async openArticle(articleId) {
      if(this.isAuthenticatedUser){
      this.getArticleData(articleId)
      this.showArticleModal = true;
      this.fetchComments(articleId);
      } else {
        this.visibleSubscriptionModal = true;
      }
    },
    async fetchComments(articleId){
      await this.$store.dispatch("discover/fetchDiscoverArticleComments", articleId);
    },
  },
};
</script>

<style lang="scss">
.headline-ticker {
  background-color: rgba(26, 37, 50, 0.685);
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7rem;
  position: relative;
  z-index: 1;
  &__marquee {
    padding: 0 1rem;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      li {
        color: $color-white;
        font-size: 1.2rem;
        font-family: $font-primary;
        margin-right: 3rem;
        vertical-align: middle;
        a {
          color: $color-white;
        }
      }
    }
  }
  .icon {
    cursor: pointer;
  }
}
.choice-menu {
  padding: 1rem !important;
}
.choice-heading {
  font-family: $font-secondary-bold;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
}
.menu-list {
  padding: 0 1rem;
  .menu-item {
    display: flex;
    margin-bottom: 0.7rem;
    .ant-checkbox-wrapper {
      width: 100%;
      .ant-checkbox {
        float: right;
        transform: translateY(35%);
        margin-left: 3rem;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $color-primary;
        border: none !important;
      }
      .ant-checkbox-inner {
        border: 0.24rem solid $color-dark-gray-4;
      }
      span {
        padding: 0;
        font-size: 1.3rem;
        font-family: $font-secondary;
      }
    }
    /*.ant-checkbox-wrapper:first-child{
        
           width: 9rem;
          display: inline-block;
        
      }*/
  }
}
</style>
