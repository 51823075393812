<template>
  <a-drawer
    placement="right"
    :closable="true"
    wrapClassName="search-management"
    width="46rem"
    @close="closeDrawer()"
  >
    <h2 class="search-management__title">Select Filters</h2>
    <!-- type="card" v-model:activeTab="activeTab" class="search-management-tab" -->
    <a-tabs class="search-management-tab">
      <a-tab-pane key="1" tab="Select Filters">
        <div class="search-management__body">
          <div class="heading">
            <button class="clear-filter" @click="clearFilters">
              Clear all filters
            </button>
          </div>

          <!-- <div class="filter-checkbox-selection">
              <a-checkbox-group >
                <a-checkbox value="Impact Articles">
                  Impact Articles
                </a-checkbox>
              </a-checkbox-group>
              <div class="numbers">
                <p>59</p>
              </div>
            </div> -->
          <!-- <a-collapse accordion>
              <a-collapse-panel key="1" header="Industrys">
                <div class="collapse-body">
                  <input type="text" placeholder="Search Industry" />
                  <div class="filter-checkbox-selection">
                    <a-checkbox-group >
                      <a-checkbox value="Impact Articles">
                        Computer Software
                      </a-checkbox>
                    </a-checkbox-group>
                    <div class="numbers">
                      <p>59</p>
                    </div>
                  </div>
                  <div class="filter-checkbox-selection">
                    <a-checkbox-group >
                      <a-checkbox value="Impact Articles">
                        Internet
                      </a-checkbox>
                    </a-checkbox-group>
                    <div class="numbers">
                      <p>59</p>
                    </div>
                  </div>
                  <div class="filter-checkbox-selection">
                    <a-checkbox-group >
                      <a-checkbox value="Impact Articles">
                        Financial Services
                      </a-checkbox>
                    </a-checkbox-group>
                    <div class="numbers">
                      <p>59</p>
                    </div>
                  </div>
                  <div class="filter-checkbox-selection">
                    <a-checkbox-group >
                      <a-checkbox value="Impact Articles">
                        Marketing & Advertising
                      </a-checkbox>
                    </a-checkbox-group>
                    <div class="numbers">
                      <p>59</p>
                    </div>
                  </div>
                </div>
              </a-collapse-panel>
            </a-collapse> -->
          <!-- <a-collapse accordion>
              <a-collapse-panel key="1" header="Location">
                <div class="collapse-body">
                  <p>Include Location</p>
                  <input type="text" placeholder="Search Industry" />
                </div>
                <div class="collapse-body">
                  <p>Exclude Location</p>
                  <input type="text" placeholder="Search Industry" />
                </div>
              </a-collapse-panel>
            </a-collapse> -->
          <h2 class="filter-heading">News Articles</h2>

          <hr />
          <a-collapse accordion>
            <a-collapse-panel key="1" header="Sources">
              <div class="collapse-body">
                <p>Include Sources</p>
                <a-select
                  v-model:value="includedSources"
                  mode="multiple"
                  style="width: 100%"
                  placeholder="Search Source"
                  :options="includeSourceOptions"
                  @change="handleIncludeSources"
                ></a-select>
              </div>

              <div class="collapse-body">
                <p>Exclude Sources</p>
                <a-select
                  v-model:value="excludedSources"
                  mode="multiple"
                  style="width: 100%"
                  placeholder="Search Source"
                  :options="excludeSourceOptions"
                  @change="handleExcludeSources"
                ></a-select>
              </div>
              <!-- <div class="collapse-body">
                  <p>Request new source</p>
                  <div class="request-new-source">
                    <input type="text" placeholder="Type source" />
                    <button>Submit</button>
                  </div>
                </div> -->
            </a-collapse-panel>
          </a-collapse>
          <a-collapse accordion>
            <a-collapse-panel key="1" header="Date Range">
              <div class="collapse-body">
                <p>From</p>
                <a-date-picker
                  placeholder="Start Date"
                  v-model:value="startDate"
                  @change="setStartDate"
                  :disabled-date="disabledStartDate"
                  @openChange="handleStartDateChange"
                />
              </div>
              <div class="collapse-body">
                <p>To</p>
                <a-date-picker
                  placeholder="End Date"
                  v-model:value="endDate"
                  @change="setEndDate"
                  :disabled-date="disabledEndDate"
                  :open="endOpen"
                  @openChange="handleEndDateChange"
                />
              </div>
            </a-collapse-panel>
          </a-collapse>

          <h2 class="filter-heading mt">Research Publications</h2>
          <hr />
          <a-collapse accordion>
            <a-collapse-panel key="1" header="Sources">
              <div class="collapse-body">
                <p>Include Sources</p>
                <a-select
                  v-model:value="insightsIncludedSources"
                  mode="multiple"
                  style="width: 100%"
                  placeholder="Search Source"
                  :options="insightsIncludeSourceOptions"
                  @change="handleInsightsIncludeSources"
                ></a-select>
              </div>
              <div class="collapse-body">
                <p>Exclude Sources</p>
                <a-select
                  v-model:value="insightsExcludedSources"
                  mode="multiple"
                  style="width: 100%"
                  placeholder="Search Source"
                  :options="insightsExcludeSourceOptions"
                  @change="handleInsightsExcludeSources"
                ></a-select>
              </div>
            </a-collapse-panel>
          </a-collapse>
          <a-collapse accordion>
            <a-collapse-panel key="1" header="Date Range">
              <div class="collapse-body">
                <p>From</p>
                <a-date-picker
                  placeholder="Start Date"
                  v-model:value="insightsStartDate"
                  @change="setInsightsStartDate"
                  :disabled-date="disabledInsightsStartDate"
                  @openChange="handleInsightsStartDateChange"
                />
              </div>
              <div class="collapse-body">
                <p>To</p>
                <a-date-picker
                  placeholder="End Date"
                  v-model:value="insightsEndDate"
                  @change="setInsightsEndDate"
                  :disabled-date="disabledInsightsEndDate"
                  :open="insightsEndOpen"
                  @openChange="handleInsightsEndDateChange"
                />
              </div>
            </a-collapse-panel>
          </a-collapse>
          <a-button
            :loading="applyingFilter"
            class="apply-filters-btn"
            @click="applyFilter"
          >
            Apply Filters
          </a-button>
          <a-button
            v-if="isAuthenticatedUser"
            class="save-search-btn"
            @click="showSaveSearchModal"
          >
            Save Filters
          </a-button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" v-if="isAuthenticatedUser">
        <template #tab>
            <span class="tabs-name" @click="getSearchHistory">
              Search History/Saved
            </span>
          </template>
        <search-saved-history></search-saved-history>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      :visible="visibleSavedSearchPopup"
      title="Give name to filter"
      @cancel="closeSavedSearchPopup"
      :footer="null"
      wrapClassName="saved-search-popup"
      :zIndex="20000000000000"
    >
      <form>
        <input type="text" v-model="filterName" placeholder="Enter name here" />
      </form>
      <p v-if="errorMsg" class="error-message">{{ errorMsg }}</p>
      <div class="btn">
        <a-button :loading="loader" class="save-btn" @click="saveSearchFilters">Save</a-button>
      </div>
    </a-modal>
  </a-drawer>
</template>

<script>
import SearchSavedHistory from "./SearchSavedHistory.vue";

export default {
  components: {
    SearchSavedHistory,
  },
  props:{
    loader: Boolean,
    visibleSavedSearchPopup: Boolean
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      includedSources: [],
      excludedSources: [],
      sourceOptions: [],
      insightsIncludedSources: [],
      insightsExcludedSources: [],
      insightsStartDate: null,
      insightsEndDate: null,
      endOpen: false,
      insightsEndOpen: false,
      applyingFilter: false,
      activeTab: "1",
      filterName: '',
      errorMsg: ''
    };
  },
  async created() {
    this.sourceOptions = this.allSources.sources;
    this.insightsSourceOptions = this.allSources.insightSources;
    this.includeSourceOptions = this.allSources.sources;
    this.excludeSourceOptions = this.allSources.sources;
    this.insightsIncludeSourceOptions = this.allSources.insightSources;
    this.insightsExcludeSourceOptions = this.allSources.insightSources;
  },
  emits: ['save-search-filter', 'show-save-search-modal', 'close-save-search-modal','close-drawer'],
  computed: {
    allSources() {
      const fetchedSources = this.$store.getters["discover/getSources"];
      const sources = [];
      const insightSources = [];
      fetchedSources.news_sources.map((source) => {
        let sourceObj = {
          value: source.source,
          label: source.source.toUpperCase(),
        };
        sources.push(sourceObj);
      });
      fetchedSources.research_institutes.map((source) => {
        let insightSourcesObj = {
          value: source.source,
          label: source.source.toUpperCase(),
        };
        insightSources.push(insightSourcesObj);
      });

      return { sources, insightSources };
    },
    isAuthenticatedUser(){
      return this.$store.getters['auth/isAuthenticated'];
    }
  },
  methods: {
    async getSearchHistory(){
      await this.$store.dispatch("discover/fetchSearchHistory");
    },
    disabledStartDate(startDate) {
      if (!startDate || !this.endDate) {
        return false;
      }
      return startDate.valueOf() > this.endDate.valueOf();
    },
    disabledEndDate(endDate) {
      if (!endDate || !this.startDate) {
        return false;
      }
      return this.startDate.valueOf() >= endDate.valueOf();
    },
    disabledInsightsStartDate(startDate) {
      if (!startDate || !this.insightsEndDate) {
        return false;
      }
      return startDate.valueOf() > this.insightsEndDate.valueOf();
    },
    disabledInsightsEndDate(endDate) {
      if (!endDate || !this.insightsStartDate) {
        return false;
      }
      return this.insightsStartDate.valueOf() >= endDate.valueOf();
    },
    handleStartDateChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndDateChange(open) {
      this.endOpen = open;
    },
    handleInsightsStartDateChange() {
      if (!open) {
        this.insightsEndOpen = true;
      }
    },
    handleInsightsEndDateChange(open) {
      this.insightsEndOpen = open;
    },
    async applyFilter() {
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
      this.applyingFilter = true;
      this.searchNewsArticles();
      this.searchInsightsArticles();
      this.applyingFilter = false;
      this.closeDrawer();
    },  
    async searchNewsArticles() {
      await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles() {
      await this.$store.dispatch("discover/searchInsights");
    },
    closeDrawer(){
      this.$emit("close-drawer");
      this.$store.commit("discover/SET_INSIGHT_DRAWER_VIEW", false);
    },
    showSaveSearchModal(){
      this.$emit('show-save-search-modal')
    },
    closeSavedSearchPopup() {
      this.filterName = '';
      this.errorMsg = '';
      this.$emit('close-save-search-modal')
    },
    saveSearchFilters(){
      if(this.filterName == ''){
        this.errorMsg = "This field is required"
        return false
      }
      this.$emit('save-search-filter', this.filterName)
      this.errorMsg = '';
      this.filterName = '';
    },
    setStartDate() {
      const startDate = this.setDateFormat(this.startDate);
      this.$store.commit("discover/SET_START_DATE", startDate);
    },
    setEndDate() {
      const endDate = this.setDateFormat(this.endDate);
      this.$store.commit("discover/SET_END_DATE", endDate);
    },

    setInsightsStartDate() {
      const startDate = this.setDateFormat(this.insightsStartDate);
      this.$store.commit("discover/SET_INSIGHTS_START_DATE", startDate);
    },
    setInsightsEndDate() {
      const endDate = this.setDateFormat(this.insightsEndDate);
      this.$store.commit("disover/SET_INSIGHTS_END_DATE", endDate);
    },
    setDateFormat(date) {
      date =
        typeof date == "object" && (date !== "") & (date !== null)
          ? date._d.getFullYear() +
            "-" +
            (date._d.getMonth() + 1) +
            "-" +
            date._d.getDate()
          : date;
      return date;
    },

    handleIncludeSources(sources) {
      // sources.map((source) => {
      //   this.excludeSourceOptions = this.excludeSourceOptions.filter(
      //     (excludeoption) => {
      //       return excludeoption.value !== source;
      //     }
      //   );
      // });

      this.excludeSourceOptions = this.sourceOptions.filter((option) =>
        sources.every((option2) => !option2.includes(option.value))
      );

      if (this.includedSources.length == 0) {
        this.$store.commit("discover/SET_INCLUDED_SOURCES", "");
      } else {
        let reqSources = "";
        this.includedSources.map((val) => {
          if (val === this.includedSources[this.includedSources.length - 1]) {
            reqSources += val;
          } else {
            reqSources += val + ",";
          }
        });
        this.$store.commit("discover/SET_INCLUDED_SOURCES", reqSources);
      }
    },

    handleExcludeSources(sources) {
      // sources.map((source) => {
      //   this.includeSourceOptions = this.includeSourceOptions.filter(
      //     (includeoption) => {
      //       return includeoption.value !== source;
      //     }
      //   );
      // });

      this.includeSourceOptions = this.sourceOptions.filter((option) =>
        sources.every((option2) => !option2.includes(option.value))
      );

      if (this.excludedSources.length == 0) {
        this.$store.commit("discover/SET_EXCLUDED_SOURCES", "");
      } else {
        let reqSources = "";
        this.excludedSources.map((val) => {
          if (val === this.excludedSources[this.excludedSources.length - 1]) {
            reqSources += val;
          } else {
            reqSources += val + ",";
          }
        });
        this.$store.commit("discover/SET_EXCLUDED_SOURCES", reqSources);
      }
    },

    handleInsightsIncludeSources(sources) {
      // sources.map((source) => {
      //   this.insightsExcludeSourceOptions = this.insightsExcludeSourceOptions.filter(
      //     (excludeoption) => {
      //       return excludeoption.value !== source;
      //     }
      //   );
      // });

      this.insightsExcludeSourceOptions = this.insightsSourceOptions.filter(
        (option) => sources.every((option2) => !option2.includes(option.value))
      );

      if (this.insightsIncludedSources.length == 0) {
        this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", "");
      } else {
        let reqSources = "";
        this.insightsIncludedSources.map((val) => {
          if (
            val ===
            this.insightsIncludedSources[
              this.insightsIncludedSources.length - 1
            ]
          ) {
            reqSources += val;
          } else {
            reqSources += val + ",";
          }
        });
        this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", reqSources);
      }
    },
    handleInsightsExcludeSources(sources) {
      // console.log("Bye")
      // sources.map((source) => {
      //   this.insightsIncludeSourceOptions = this.insightsIncludeSourceOptions.filter(
      //     (includeoption) => {
      //       return includeoption.value !== source;
      //     }
      //   );
      // });

      this.insightsIncludeSourceOptions = this.insightsSourceOptions.filter(
        (option) => sources.every((option2) => !option2.includes(option.value))
      );

      if (this.insightsExcludedSources.length == 0) {
        this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", "");
      } else {
        let reqSources = "";
        this.insightsExcludedSources.map((val) => {
          if (
            val ===
            this.insightsExcludedSources[
              this.insightsExcludedSources.length - 1
            ]
          ) {
            reqSources += val;
          } else {
            reqSources += val + ",";
          }
        });
        this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", reqSources);
      }
    },
    async clearFilters() {
      this.includedSources = [];
      this.excludedSources = [];
      this.startDate = null;
      this.endDate = null;
      this.insightsIncludedSources = [];
      this.insightsExcludedSources = [];
      this.insightsStartDate = null;
      this.insightsEndDate = null;
      this.$store.commit("discover/SET_INCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_EXCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_START_DATE", "");
      this.$store.commit("discover/SET_END_DATE", "");
      this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_INSIGHTS_START_DATE", "");
      this.$store.commit("discover/SET_INSIGHTS_END_DATE", "");
      this.$store.commit("discover/SET_SEARCH_QUERY", "");
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
      this.searchNewsArticles();
      this.searchInsightsArticles();
    },
  },
};
</script>

<style lang="scss">
.search-management {
  @include reset-css;
  .ant-drawer-body {
    padding: 5rem 4rem 2rem;
  }
  .ant-drawer-close {
    top: 4rem;
    right: 3rem;
    font-size: 2rem;
    color: $color-black;
  }

  &__title {
    font-size: 2.5rem;
    font-family: $font-secondary-bold;
    margin-bottom: 2rem;
  }
  .search-management-tab {
    .ant-tabs-bar {
      margin: 0;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      background-color: transparent !important;
      border: none;
      font-size: 1.6rem;
      font-family: $font-secondary-bold;
      font-weight: 500;
      color: $color-black;
      opacity: 0.5;
      padding-right: 3rem !important;
      padding: 0;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      text-shadow: none;
      opacity: 1 !important;
      font-family: $font-secondary-bold;
      font-weight: 900;
      position: relative;
    }
    .ant-tabs-nav .ant-tabs-tab-active::after {
      content: "";
      width: 2.7rem;
      height: 0.4rem;
      background-color: $color-primary;
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      opacity: 1;
      top: 3.6rem;
      @include respond(phone-x-small) {
        top: 4.6rem;
      }
    }
    .ant-tabs-nav-wrap {
      background-color: #eeeeee;
      padding-bottom: 2rem;
      .ant-tabs-nav {
        .ant-tabs-tab {
          padding: 1rem 0;
          color: $color-black;
          font-size: 1.6rem;
          position: relative;
          &:hover {
            color: $color-black;
          }
          .tabs-notification {
            position: absolute;
            top: 7px;
            right: -8px;
            width: 0.8rem;
            height: 0.8rem;
            font-size: 0.8rem;
            background-color: red;
            line-height: normal;
            border-radius: 100%;
            font-family: $font-secondary;
          }
        }
        .ant-tabs-tab-active {
          &::after {
            width: 4rem !important;
            top: 3.6rem;
          }
        }
        .ant-tabs-ink-bar {
          display: none;
          background-color: transparent;
        }
      }
    }
  }
  &__header {
    box-shadow: -17px 3px 94px #0000001a;
    background-color: #eeeeee;
    padding: 0 4rem;
    padding-bottom: 1rem;
    .active {
      font-family: $font-secondary-bold;
      opacity: 1;
    }
  }
  &__body {
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 2rem;
    padding-bottom: 3rem;
    .filter-heading {
      font-family: $font-primary-bold;
      font-weight: 900;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    .mt {
      margin-top: 3rem;
    }
    .filter-heading + hr {
      color: rgba(0, 0, 0, 0.3);
    }
    .heading {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 1rem 0;
      h2 {
        font-size: 2.5rem;
        font-family: $font-secondary-bold;
        font-weight: 900;
      }
      .clear-filter {
        cursor: pointer;
        margin: 1rem 0;
        border: none;
        background: none;
        font-size: 1.6rem;
        font-family: $font-secondary;
      }
    }
    .filter-checkbox-selection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;
      .ant-checkbox + span {
        font-size: 1.6rem;
        font-family: $font-secondary;
      }
      .ant-checkbox-inner {
        background-color: #aaaaaa;
        border-radius: 0.3rem;
        border: none;
      }
      .ant-checkbox-inner::after {
        left: 28%;
      }
      .numbers {
        padding: 0.2rem 0.8rem;
        background-color: #c2c2c2;
        border-radius: 0.3rem;
        width: 3rem;
        p {
          font-size: 1.2rem;
          font-family: $font-secondary;
        }
      }
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 0;
    }
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      right: 0 !important;
      left: inherit;
    }
    .ant-collapse {
      border: none;
      padding: 0;
      background-color: transparent;
      .ant-collapse-header {
        font-size: 1.9rem;
        font-family: $font-secondary-bold;
        padding: 1.5rem 0 !important;
      }
      .ant-collapse-item {
        border: none;
      }
      .ant-collapse-content {
        border: none;
        background-color: transparent;
      }
    }
    .collapse-body {
      margin-bottom: 0.5rem;
      .ant-calendar-picker {
        width: 100%;
      }
      .ant-select {
        font-size: 1.6rem;
        .ant-select-selector {
          padding: 0;
          .ant-select-selection-item {
            margin: 0.5rem;
          }
        }
      }
      .request-new-source {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          height: 4.2rem;
          padding: 0 3rem;
          border: none;
          font-family: $font-secondary;
          font-size: 1.6rem;
          background-color: $color-primary;
          color: $color-white;
        }
      }
      input {
        height: 4.2rem;
        border: none;
        background-color: $color-white;
        width: 100%;
        padding: 0 1.5rem;
        font-size: 1.6rem;
        font-family: $font-secondary;
        outline: none;
      }
      p {
        font-size: 1.6rem;
        font-family: $font-secondary;
        margin: 0.8rem 0 0.6rem;
      }
    }
    .apply-filters-btn {
      cursor: pointer;
      height: 6rem;
      width: 100%;
      border: none;
      font-family: $font-secondary-bold;
      margin: 2rem 0;
      font-size: 1.8rem;
      background-color: $color-primary;
      color: $color-white;
      &:hover,
      &:active,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
      }
      span {
        color: $color-white;
      }
    }
    .save-search-btn {
      cursor: pointer;
      height: 6rem;
      width: 100%;
      border: none;
      font-family: $font-secondary-bold;
      margin: 0 0 3rem;
      font-size: 1.8rem;
      background-color: rgb(204, 204, 204);
      color: $color-black;
      margin-bottom: 4rem;
      &:hover,
      &:active,
      &:focus {
        background-color: rgb(204, 204, 204);
        color: $color-black;
      }
    }
    .ant-btn-loading {
      .anticon {
        svg {
          color: $color-primary;
        }
      }
    }
  }
  &__body::-webkit-scrollbar-track {
    background-color: #ffffff3f;
  }

  &__body::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 3rem;
  }

  &__body::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 3rem;
  }
  .ant-drawer-content {
    background-color: #eeeeee;
    .ant-drawer-wrapper-body {
      overflow: hidden;
    }
  }
  .ant-drawer-header {
    background-color: #eeeeee;
    padding: 6rem 4rem 1.5rem;
    padding-top: 6rem;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background-color: transparent !important;
    border: none;
    font-size: 1.6rem;
    font-family: $font-secondary;
    color: $color-black;
    opacity: 0.5;
    padding-left: 0;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    text-shadow: none;
    opacity: 1 !important;
  }
}
.search-management {
  overflow: hidden !important;
}
.saved-search-popup {
  .ant-modal {
    width: 50rem !important;
    top: 18rem;
  }
  .ant-modal-content {
    padding: 2.5rem;
    box-shadow: 0px 0px 94px #00000047;
    background: #f6f8ff;
    border-radius: 0;
    .ant-modal-header {
      padding: 0;
      border: none;
      background: #f6f8ff;
      .ant-modal-title {
        font-size: 2.4rem;
        font-family: $font-secondary-bold;
        width: 34.8rem;
      }
    }
    .ant-modal-body {
      margin-top: 0.8rem;
      p {
        font-size: 1.6rem;
        font-family: $font-secondary;
        margin: 0;
        opacity: 0.5;
      }
      form {
        margin-top: 2.6rem;
        p {
          opacity: 1;
          margin-bottom: 1.3rem;
        }
        input {
          height: 4.2rem;
          font-size: 1.6rem;
          font-family: $font-secondary;
          width: 100%;
          padding: 0 1.5rem;
          border: 0.2rem solid #70707021;
          outline: none;
        }
        input::placeholder {
          opacity: 0.5;
        }
      }
      .search-saved {
        font-size: 1.6rem;
          font-family: $font-primary;
          margin-bottom: 0;
          margin-top: 1rem;
          opacity: 1;
          color: green;
      }
      .error-message {
        font-size: 1.6rem;
          font-family: $font-primary;
          margin-bottom: 0;
          margin-top: 1rem;
          opacity: 1;
          color: red;
      }
      .btn {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .save-btn {
          background-color: $color-primary;
          color: $color-white;
          width: 12rem;
          height: 4.1rem;
          font-size: 1.6rem;
          font-family: $font-primary-semibold;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
