<template>
  <div class="search-for-topics">
    <div class="search-for-topics__left">
      <!-- <div v-if="engage" class="navbar">
        <router-link to="/engage/impact" class="nav-link">Impact</router-link>
        <router-link to="/engage/fund-raiser" class="nav-link"
          >Fund Raisers</router-link
        >
      </div> -->
      <h2>Welcome to Your News Intelligence Environment</h2>
      <!-- <div class="searching">
        <form @submit.prevent="searchArticle" class="form">
          <i class="icon icon-search"></i>
          <input
            type="text"
            v-model="searchTopic"
            placeholder="Enter Search Terms"
          />
        </form>
        <div v-if="!engage" class="filter" @click="visibleDrawer">
          <div v-if="noOfFiltersApplied != 0" class="no-of-filters">
            {{ noOfFiltersApplied }}
          </div>
          <i class="icon icon-filter_list"></i>
        </div>
      </div> -->
      <div class="catagories">
        <div
          v-for="(category, index) in catagories"
          :key="index"
          class="catagory"
        >
          <button>{{ category }}</button>
        </div>
      </div>
    </div>
    <!-- <div v-if="engage" class="article-headline">
      <article-heading @change-slide="changeSlide"></article-heading>
    </div> -->
    <!-- API needed  -->
    <!-- <div v-else class="search-for-topics__right">
      <p>Image by</p>
      <h4>Fecillia Jerreies</h4>
      <a href="">Submit artwork?</a>
    </div> -->
  </div>
  <serach-filter
    v-if="sources"
    :loader="saveFilterLoading"
    @save-search-filter="saveSearch"
    :visible="visibleFilterDrawer"
    @close-drawer="visibleFilterDrawer = false"
    @show-save-search-modal="openModal"
    :visibleSavedSearchPopup="visibleSavedSearchPopup"
    @close-save-search-modal="closeModal"
  ></serach-filter>
  <message-toaster 
  :visible="showMessageModal"
  :message="message"
  @handle-close="showMessageModal = false"
  >
  </message-toaster>
  <subscription-modal 
    :visible="visibleSubscriptionModal" 
    :visibleSignUp="visibleSubscriptionModal"
    @cancel="visibleSubscriptionModal = false"
    :footer="null"
  ></subscription-modal>
</template>

<script>
import SerachFilter from "./SearchFilter.vue";
// import ArticleHeading from "../../EngageComponents/ArticleHeadline.vue";
import SubscriptionModal from "../../../components/Auth/AccessModal.vue"

import MessageToaster from '../../BaseComponents/MessageToaster.vue';

export default {
  components: {
    SerachFilter,
    // ArticleHeading,
    MessageToaster,
    SubscriptionModal,
  },
  data() {
    return {
      visibleFilterDrawer: false,
      searchTopic: "",
      catagories: [],
      saveFilterLoading: false,
      visibleSavedSearchPopup: false,
      message: {},
      showMessageModal: false,
      sources: false,
      visibleSubscriptionModal: false
    };
  },
  props: { engage: Boolean },
  computed: {
    noOfFiltersApplied() {
      let count = 0;
      if (
        this.$store.getters["discover/getIncludedSources"] ||
        this.$store.getters["discover/getExcludedSources"] ||
        this.$store.getters["discover/getInsightsIncludedSources"] ||
        this.$store.getters["discover/getInsightsExcludedSources"]
      ) {
        count++;
      }
      if (
        this.$store.getters["discover/getStartDate"] ||
        this.$store.getters["discover/getEndDate"] ||
        this.$store.getters["discover/getInsightsStartDate"] ||
        this.$store.getters["discover/getInsightsEndDate"]
      ) {
        count++;
      }
      // if (this.$store.getters['discover/getSearchQuery']) {
      //   count++;
      // }
      return count;
    },
    isAuthenticatedUser(){
      return this.$store.getters['auth/isAuthenticated'];
    }
  },
  methods: {
    async visibleDrawer() {
      if(this.isAuthenticatedUser){
      // const response = await this.$store.dispatch("discover/getAllSources");
      // if(response.status == 200){
      //   this.sources = true;
      // }
      }else{
        this.visibleSubscriptionModal = true;
      // try{
      // const response = await this.$store.dispatch("discover/getAllSourcesAnonymous");
      // if(response.status == 200){
      //   this.sources = true;
      // }
      // }catch(error){
      //   if(error.response.status){
      //     this.visibleSubscriptionModal = true;
      //   }
      // }
      }
      this.visibleFilterDrawer = true;
    },
    // changeSlide(img) {
    //   this.$emit("change-slide", img);
    // },
    async searchArticle() {
      if(this.isAuthenticatedUser){
      let articleRequestObj = {
        query: this.searchTopic,
        fromDate: this.$store.getters["discover/getStartDate"],
        toDate: this.$store.getters["discover/getEndDate"],
        includeSource: this.$store.getters["discover/getIncludedSources"],
        excludeSource: this.$store.getters["discover/getExcludedSources"],
        limit: 10,
        offset: 0,
      };
      let insightsRequestObj = {
        query: this.searchTopic,
        fromDate: this.$store.getters["discover/getInsightsStartDate"],
        toDate: this.$store.getters["discover/getInsightsEndDate"],
        includeSource:
          this.$store.getters["discover/getInsightsIncludedSources"],
        excludeSource:
          this.$store.getters["discover/getInsightsExcludedSources"],
        limit: 10,
        offset: 0,
      };
      const impactRequestObj = {
        query: this.searchTopic,
        offset: 0,
        limit: 9,
        author: ""
      };
      await this.$store.dispatch("discover/searchArticles", articleRequestObj);
      await this.$store.dispatch("discover/searchInsights", insightsRequestObj);
      await this.$store.dispatch("impact/fetchImpactArticle", impactRequestObj);
      this.$store.commit("discover/SET_SEARCH_QUERY", this.searchTopic);
      // if(this.isAuthenticatedUser){
      // }else{
      // await this.$store.dispatch("discover/searchArticlesAnonymousUser", articleRequestObj);
      // await this.$store.dispatch("discover/searchInsightsAnonymousUser", insightsRequestObj);
      // }
      this.emitter.emit("search-article");
      } else {
        this.visibleSubscriptionModal = true;
      }
    },
    openModal() {
      this.visibleSavedSearchPopup = true;
    },
    closeModal() {
      this.visibleSavedSearchPopup = false;
    },
    async saveSearch(name) {
      this.saveFilterLoading = true;
      const payload = {
        filter_name: name,
        filters: {
          news_sources: {
            search_query: this.searchTopic,
            from_date: this.$store.getters["discover/getStartDate"],
            to_date: this.$store.getters["discover/getEndDate"],
            include_source: this.$store.getters["discover/getIncludedSources"]
              ? this.$store.getters["discover/getIncludedSources"].split(",")
              : [],
            exclude_source: this.$store.getters["discover/getExcludedSources"]
              ? this.$store.getters["discover/getExcludedSources"].split(",")
              : [],
          },
          research_publications: {
            search_query: this.searchTopic,
            from_date: this.$store.getters["discover/getInsightsStartDate"],
            to_date: this.$store.getters["discover/getInsightsEndDate"],
            include_source: this.$store.getters[
              "discover/getInsightsIncludedSources"
            ]
              ? this.$store.getters["discover/getInsightsIncludedSources"].split(
                  ","
                )
              : [],
            exclude_source: this.$store.getters[
              "discover/getInsightsExcludedSources"
            ]
              ? this.$store.getters["discover/getInsightsExcludedSources"].split(
                  ","
                )
              : [],
          },
        },
      };
      const response = await this.$store.dispatch(
        "discover/saveSearchFilter",
        payload
      );
      if (response.status === 201) {
        this.$store.dispatch("discover/fetchSavedSearchFilter");
        this.saveFilterLoading = false;
        this.visibleSavedSearchPopup = false;
        this.showMessageModal = true;
        this.message = {
          title: "Filters Saved Successfully",
          type: "success",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@include reset-css;

.search-for-topics {
  padding: 1rem 7rem;
  // padding-top: 4rem;
  // padding-bottom: 6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 6rem 0 2rem;

  @include respond(tab-port) {
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  &__left {
    margin-top: 0;

    @include respond(phone-large) {
      width: 100%;
    }

    .navbar {
      margin: 1rem 0;

      a {
        font-size: 1.8rem;
        font-family: $font-secondary;
        color: $color-white;
        opacity: 0.8;
        margin-right: 3rem;
      }

      .router-link-active {
        opacity: 1;
        font-family: $font-secondary-bold;
      }
    }

    h2 {
      font-size: 3.8rem;
      font-family: $font-secondary-bold;
      color: $color-white;
      margin-bottom: 0;
      line-height: 5rem;
    }

    .searching {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .form {
        background-color: $color-white;
        width: 57rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 6.2rem;
        margin-right: 1rem;

        @include respond(laptop-small) {
          width: 50rem;
        }

        @include respond(phone-large) {
          width: 100%;
        }

        input {
          background-color: transparent;
          width: 100%;
          border: none;
          outline: none;
          height: 100%;
          font-size: 1.6rem;
          font-family: $font-primary-bold;
        }

        input::placeholder {
          opacity: 0.4;
        }

        .icon {
          padding: 0 2rem;
          font-size: 2rem;
        }
      }

      .filter {
        background-color: $color-white;
        height: 6.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6.2rem;
        cursor: pointer;
        position: relative;

        .no-of-filters {
          position: absolute;
          top: 35%;
          right: 35%;
          transform: translate(100%, -100%);
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-color: $color-primary;
          font-family: $font-primary-bold;
          color: $color-white;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon {
          font-size: 3rem;
        }
      }
    }

    // .catagories {
    //   margin: 2.5rem 0;
    //   display: grid;
    //   grid-template-columns: auto auto auto auto auto;
    //   gap: 1.5rem;
    //   justify-content: flex-start;

    //   @include respond(laptop-small) {
    //     grid-template-columns: auto auto auto;
    //   }

    //   @include respond(tab-port) {
    //     grid-template-columns: auto auto auto auto auto;
    //   }

    //   @include respond(phone-x-small) {
    //     grid-template-columns: auto auto auto;
    //   }

    //   .catagory {
    //     width: fit-content;

    //     button {
    //       background-color: rgba(0, 0, 0, 0.356);
    //       border: 0.1rem solid $color-white;
    //       padding: 0.5rem 1.3rem;
    //       font-size: 1.6rem;
    //       font-family: $font-secondary;
    //       color: $color-white;
    //       text-align: center;
    //       width: fit-content;
    //       cursor: pointer;
    //     }
    //   }
    // }
  }

  .article-headline {
    @include respond(tab-port) {
      display: none;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%)
      0% 0% no-repeat padding-box;
    padding: 1rem 3rem;

    @include respond(tab-port) {
      width: 100%;
    }

    p {
      font-size: 1.2rem;
      color: $color-white;
      font-family: $font-primary;
    }

    h4 {
      font-size: 1.8rem;
      color: $color-white;
      margin-bottom: 0.7rem;
      font-family: $font-primary-semibold;
    }

    a {
      color: $color-white;
      font-size: 1.6rem;
      font-family: $font-primary;
    }
  }
}
</style>
