<template>
  <div class="search-saved-history">
    <div class="search-filter" v-if="!impact">
      <div class="search-filter__title">
        <h4>Saved Searches</h4>
      </div>
      <search-saved-card
        @show-delete-popup="visibleSavedSearchDeleteModal"
      ></search-saved-card>
      <div v-if="skeletonLoader">
        <a-skeleton
          v-for="index in 5"
          :key="index"
          class="save-search-card-skeleton"
          active
          :title="{ width: 'full' }"
          :paragraph="{ rows: 0, width: '20rem' }"
        />
      </div>
    </div>
    <div class="search-filter">
      <div class="search-filter__title">
        <h4>Search History</h4>
        <!-- <p>See all</p> -->
      </div>
      <search-history-card
        :impact="impact"
        @show-delete-popup="visibleSearchHistoryDeleteModal"
      ></search-history-card>
      <div v-if="skeletonLoader">
        <a-skeleton
          v-for="index in 5"
          :key="index"
          class="save-search-card-skeleton"
          active
          :title="{ width: 'full' }"
          :paragraph="{ rows: 0, width: '20rem' }"
        />
      </div>
    </div>
  </div>
  <a-modal
    :visible="savedSearchDeleteModal"
    title="Are you sure you want to delete this filter?"
    @cancel="closeDeletePopup"
    :footer="null"
    wrapClassName="delete-save-search-popup"
  >
    <p>
      You will not be able to recover this. The search will be permanently
      deleted.
    </p>
    <div class="buttons">
      <a-button
        :loading="loader"
        class="btn danger"
        @click="deleteSaveSearch()"
        >Yes, Delete</a-button
      >
      <button class="btn" @click="closeDeletePopup">No, Don't Delete</button>
    </div>
  </a-modal>

  <a-modal
    :visible="recentSearchDeleteModal"
    title="Are you sure you want to delete this search?"
    @cancel="closeDeletePopup"
    :footer="null"
    wrapClassName="delete-save-search-popup"
  >
    <p>
      You will not be able to recover this. The search will be permanently
      deleted.
    </p>
    <div class="buttons">
      <a-button
        :loading="loader"
        class="btn danger"
        @click="deleteRecentSearch()"
        >Yes, Delete</a-button
      >
      <button class="btn" @click="closeDeletePopup">No, Don't Delete</button>
    </div>
  </a-modal>
</template>

<script>
import SearchSavedCard from "./SearchSavedCard.vue";
import SearchHistoryCard from "./SearchHistoryCard.vue";

export default {
  components: {
    SearchSavedCard,
    SearchHistoryCard,
  },
  mounted() {
    if(this.impact){
      this.getImpactSearchHistory();
    }else{
    this.getSavedSearchFilter();
    this.getArticleSearchHistory();
    }
  },
  props: { impact: Boolean },
  data() {
    return {
      savedSearchDeleteModal: false,
      recentSearchDeleteModal: false,
      savedSearchId: "",
      recentSearchId: "",
      loader: false,
      skeletonLoader: false,
    };
  },
  methods: {
    visibleSavedSearchDeleteModal(value) {
      this.savedSearchId = value;
      this.savedSearchDeleteModal = true;
    },
    visibleSearchHistoryDeleteModal(value) {
      this.recentSearchId = value;
      this.recentSearchDeleteModal = true;
    },
    closeDeletePopup() {
      this.savedSearchDeleteModal = false;
      this.recentSearchDeleteModal = false;
    },
    async getSavedSearchFilter() {
      this.skeletonLoader = true;
      await this.$store.dispatch("discover/fetchSavedSearchFilter");
      this.skeletonLoader = false;
    },
    async getArticleSearchHistory() {
      this.skeletonLoader = true;
      await this.$store.dispatch("discover/fetchSearchHistory");
      this.skeletonLoader = false;
    },
    async getImpactSearchHistory() {
      this.skeletonLoader = true;
      await this.$store.dispatch("impact/fetchimpactSearchHistory");
      this.skeletonLoader = false;
    },
    async deleteSaveSearch(type) {
      this.loader = true;
      const response = await this.$store.dispatch(
        "discover/deleteSavedSearchFilter",
        this.savedSearchId
      );
      if (response.status == 204) {
        this.getSavedSearchFilter();
        this.loader = false;
        this.savedSearchDeleteModal = false;
      }
    },
    async deleteRecentSearch() {
      this.loader = true;
      const response = await this.$store.dispatch(
        "discover/deleteRecentSearchHistory",
        this.recentSearchId
      );
      if (response.status == 204) {
        this.getArticleSearchHistory();
        this.getImpactSearchHistory();
        this.loader = false;
        this.recentSearchDeleteModal = false;
      }
    },
    
  },
};
</script>

<style lang="scss">
@include reset-css;
.search-saved-history {
  height: 24rem;
  overflow-x: hidden;
  overflow-y: auto;
  // padding-bottom: 6rem !important;
  .search-filter {
    // padding-right: 2rem !important;
    border-bottom: none;
    padding: 0 0;
    width: 100%;
    margin: 1rem 0 0 auto !important;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      h4 {
        font-size: 1.4rem;
        font-family: $font-secondary-bold;
        font-weight: 900;
        margin-bottom: 0;
      }
      p {
        font-size: 1.6rem;
        font-family: $font-secondary;
        cursor: pointer;
      }
    }
    .save-search-card-skeleton {
      .ant-skeleton-content {
        .ant-skeleton-title {
          margin-bottom: 1.5rem;
          margin-top: 0;
          height: 7rem;
        }
      }
    }
  }
  .search-filter:not(:last-child) {
    border-bottom: 0.22rem solid rgba(124, 124, 124, 0.226);
  }
}
.delete-save-search-popup {
  .ant-modal {
    width: 50rem !important;
    top: 18rem;
  }
  .ant-modal-content {
    padding: 2rem 2.5rem;
    border-radius: 0;
    .ant-modal-header {
      padding: 0;
      border: none;
      .ant-modal-title {
        font-size: 2.4rem;
        font-family: $font-secondary-bold;
        width: 34.8rem;
      }
    }
    .ant-modal-body {
      margin: 1rem 0;
      p {
        font-size: 1.6rem;
        font-family: $font-secondary;
        margin: 0;
        margin-bottom: 1.2rem;
        opacity: 0.5;
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn {
          width: 20rem;
          height: 4.1rem;
          font-size: 1.6rem;
          font-family: $font-primary-semibold;
          border: none;
          background-color: #d3dcfb;
          cursor: pointer;
        }
        .danger {
          background-color: #be0000 !important;
          color: $color-white;
          .anticon {
            svg {
              color: #be0000;
            }
          }
        }
      }
    }
  }
  .search-filter:first-child {
    padding-top: 0;
  }
}
.search-saved-history::-webkit-scrollbar-track {
  background-color: #ffffff3f;
}

.search-saved-history::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  border-radius: 3rem;
}

.search-saved-history::-webkit-scrollbar-thumb {
  background-color: $color-primary;
  border-radius: 3rem;
}
</style>
